import { App, reactive } from "vue";
import {
  EventMessage,
  EventError,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
  AccountInfo,
} from "@azure/msal-browser";
import { User } from "../authentication/user/user";

type AccountIdentifiers = Partial<Pick<AccountInfo, "homeAccountId" | "localAccountId" | "username">>;

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(arrayA: Array<AccountIdentifiers>, arrayB: Array<AccountIdentifiers>): boolean {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return false;
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    );
  });
}

export const msalPlugin = {
  install: (app: App, msalInstance: PublicClientApplication) => {
    const useMockedToken = User.useMockedToken("db3f55a4-277c-49ba-8f7a-70f553d37db4");

    const inProgress = InteractionStatus.Startup as InteractionStatus;
    const accounts = useMockedToken ? [] : msalInstance.getAllAccounts();
    const user = new User();

    const state = reactive({
      instance: msalInstance,
      inProgress: inProgress,
      accounts: accounts,
      isAuthenticated: false,
      user: user,
    });

    app.config.globalProperties.$msal = state;

    msalInstance.addEventCallback(async (message: EventMessage) => {
      if (useMockedToken) return;

      const logger = state.instance.getLogger();

      switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.LOGOUT_END:
          const logutEndAccounts = msalInstance.getAllAccounts();
          if (!accountArraysAreEqual(logutEndAccounts, state.accounts)) {
            state.accounts = logutEndAccounts;
          }
          break;
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          const currentAccounts = msalInstance.getAllAccounts();
          if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
            state.accounts = currentAccounts;
          }

          state.isAuthenticated = false;
          localStorage.setItem("signinRedirect", "/");
          state.user = new User();

          if (accounts.length < 1) {
            if (message.error) {
              const eventError = message.error as EventError;
              if (eventError.message && eventError.message.indexOf("AADB2C90118") > -1) {
                return; //Redirect to forgot password page for Azure B2C
              }
            }

            window.location.href = "/"; //Redirect to home page, if no accounts / is logged out
            return;
          }

          const logoutRedirectUri = window.location.origin + "/";
          const currentAccount = accounts[0];

          User.clearCache(currentAccount.idTokenClaims["sub"]);

          await state.instance.logoutRedirect({
            account: currentAccount,
            postLogoutRedirectUri: logoutRedirectUri,
          });

          logger.info("msalPlugin - Acquire token failure. User account was removing from cache");
          break;
      }

      const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
      if (status !== null) {
        state.inProgress = status;
      }
    });
  },
};
