import axios from "axios";
import { InteractionStatus, PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { b2cPolicies } from "../plugins/policies";
import { loginRequest, apiUrl } from "../plugins/auth-config";
import { getCurrentInstance, Ref, toRefs } from "vue";
import { User } from "./user/user";
import { AuthHeaders } from "./auth-headers";
import { IdentityEmployee } from "./user/identity-employee";
import { EmployeeState } from "./user/employee-state";

export type AuthContext = {
  instance: PublicClientApplication;
  accounts: Ref<AccountInfo[]>;
  inProgress: Ref<InteractionStatus>;
  isAuthenticated: Ref<boolean>;
  getAuthHeaders: () => Promise<AuthHeaders>;
  getUser: () => Promise<User>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

export function useAuth(): AuthContext {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    const errorMessage = {
      status: 501,
      message: "useAuth() cannot be called outside the setup() function of a component",
    };
    throw errorMessage;
  }
  const { instance, accounts, inProgress, isAuthenticated, user } = toRefs(
    internalInstance.appContext.config.globalProperties.$msal
  );

  const mockId = "db3f55a4-277c-49ba-8f7a-70f553d37db4";
  const useMockedToken = User.useMockedToken(mockId);

  if (!instance || !accounts || !inProgress || !isAuthenticated || !user) {
    const errorMessage = {
      status: 501,
      message: "Please install the msalPlugin",
    };
    if (!useMockedToken) throw errorMessage;
  }

  if (useMockedToken) {
    if (inProgress !== undefined) inProgress.value = InteractionStatus.None;
    if (isAuthenticated !== undefined) isAuthenticated.value = true;
    if (accounts !== undefined) accounts.value = [{ name: "mock" } as AccountInfo];
  } else if (inProgress !== undefined && inProgress.value === InteractionStatus.Startup) {
    instance.value.handleRedirectPromise().catch((error: any) => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
      if (error.errorMessage && error.errorMessage.indexOf("AADB2C90118") > -1) {
        instance.value.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword,
          scopes: loginRequest.scopes,
        });
      }
      return new User();
    });
  }

  const getUser = async (): Promise<User> => {
    if (useMockedToken) {
      const userCache = User.loadFromCache(mockId);
      if (isAuthenticated !== undefined)
        isAuthenticated.value = userCache.getEmployee().employeeState === EmployeeState.Active;
      if (user !== undefined) user.value = userCache;
      return userCache;
    }

    if (accounts.value && accounts.value.length > 0) {
      const identityId = accounts.value[0].idTokenClaims["sub"];

      if (user.value.employees.length > 0 && user.value.getEmployee().identityId === identityId) {
        return user.value;
      }

      const userCache = User.loadFromCache(identityId);
      if (userCache !== null) {
        isAuthenticated.value = userCache.getEmployee().employeeState === EmployeeState.Active;
        user.value = userCache;
        return userCache;
      }

      try {
        const responseToken = await instance.value.acquireTokenSilent(loginRequest);
        const config = { headers: { Authorization: `Bearer ${responseToken.accessToken}` } };

        const response = await axios.get(`${apiUrl.employee}/identities`, config);
        const identityEmployees = response.data as IdentityEmployee[];
        user.value.employees = identityEmployees;

        const employee = user.value.getEmployee();

        user.value.useClient(employee.defaultClientId);
        isAuthenticated.value = employee.employeeState === EmployeeState.Active;
        user.value.isAuthenticated = isAuthenticated.value;

        user.value.saveToCache();
        return user.value;
      } catch (error) {
        instance.value.getLogger().error("useAuth - acquireTokenSilent failed: " + error);
        await logout();
      }
    }

    isAuthenticated.value = false;
    user.value = new User();
    return user.value;
  };

  const login = async (): Promise<void> => {
    localStorage.setItem("signinRedirect", "/");
    if (useMockedToken) return;

    await instance.value.loginRedirect(loginRequest);
  };

  const logout = async (): Promise<void> => {
    const logger = instance.value.getLogger();

    logger.info("useAuth - Logout");

    localStorage.setItem("signinRedirect", "/");
    isAuthenticated.value = false;
    user.value = new User();

    if (useMockedToken) {
      User.clearCache(mockId);
      window.location.href = "/";
      return;
    }

    try {
      if (!accounts.value || accounts.value.length < 1) {
        logger.info("useAuth - No account found in cache");
        window.location.href = "/";
        return;
      }

      const logoutRedirectUri = window.location.origin + "/";
      const currentAccount = accounts.value[0];

      User.clearCache(currentAccount.idTokenClaims["sub"]);

      await instance.value.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: logoutRedirectUri,
      });

      logger.info("useAuth - User account was removed from cache");
    } catch (error) {
      logger.error("useAuth - Logout exception: " + error);
      window.location.href = "/";
    }
  };

  const getAuthHeaders = async (): Promise<AuthHeaders> => {
    let token = "using mock token";
    if (!useMockedToken) {
      const responseToken = await instance?.value.acquireTokenSilent(loginRequest);
      token = responseToken.accessToken;
    }
    const user = await getUser();
    const client = user.getClient();

    const authHeaders: AuthHeaders = {
      accessToken: token,
      companyId: client.companyId,
      clientId: client.id,
    };

    return authHeaders;
  };

  return {
    instance: instance === undefined ? {} : instance?.value,
    accounts,
    inProgress,
    isAuthenticated,
    getAuthHeaders,
    getUser,
    login,
    logout,
  };
}
