import { Subject } from "rxjs"; //Observable
import { Observables } from "./event-bus-observables";
import { EventBusMessage } from "./event-bus-message";
import { Topic } from "./event-bus-topic";

let observableForLanguage: Subject<any> | null = null;

export default {
  getObservable(frontend: Observables): Subject<any> | null {
    switch (frontend) {
      case Observables.LANGUAGE:
        if (!observableForLanguage) {
          observableForLanguage = new Subject();
        }
        return observableForLanguage;
    }
  },

  send(message: EventBusMessage): void {
    switch (message.topic) {
      case Topic.LANGUAGE_CHANGED:
        if (observableForLanguage) {
          observableForLanguage.next(message);
        }
        break;
    }
  },
};
