import { msalInstance, initMsalInstance } from "../../plugins/auth-config";
import { type AuthenticationResult, LogLevel, EventType } from "@azure/msal-browser";

export const mockAuthForCypressComponent = async () => {
  initMsalInstance("https://mock-cumulus-gateway.net", "", "ddbd61a6-4337-46ce-9b4f-a81d10d93f6f", "", LogLevel.Error);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance().then((msal) => {
    const accounts = msal.getAllAccounts();
    if (accounts.length > 0) {
      msal.setActiveAccount(accounts[0]);
    }
  });

  msalInstance().then((msal) => {
    msal.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msal.setActiveAccount(account);
      }
    });
  });

  return msalInstance;
};
