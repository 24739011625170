import { Topic } from "./event-bus-topic";

export class EventBusMessage {
  topic: Topic = Topic.NONE;
  commandId: Number = Date.now();
  payload: any | null = null;

  constructor(topic: Topic, payload: any | null = null) {
    this.topic = topic;
    this.payload = payload;
  }
}
