import { LogLevel, PublicClientApplication, Configuration } from "@azure/msal-browser";
import { b2cPolicies } from "./policies";
import { User } from "../authentication/user/user";

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

let mInstance: PublicClientApplication | null = null;

const setConfiguration = (
  domain: string,
  clientId: string,
  instance: string,
  redirectUri: string,
  useLogLevel = LogLevel.Info
) => {
  const authoritySignIn = "b2c_1_signin"; // Choose sign-up/sign-in user-flow as your default.
  const authorityForgotPassword = "b2c_1_resetpassword"; // Choose sign-up/sign-in user-flow as your default.

  loginRequest.scopes = [`https://${domain}/${clientId}/api-access`];

  b2cPolicies.name.signIn = authoritySignIn;
  b2cPolicies.name.forgotPassword = authorityForgotPassword;
  b2cPolicies.authorities.signIn = `${instance}/${domain}/${authoritySignIn}`;
  b2cPolicies.authorities.forgotPassword = `${instance}/${domain}/${authorityForgotPassword}`;

  if (useLogLevel >= LogLevel.Info) {
    console.log("b2c domain: " + domain);
    console.log("b2c clientId: " + clientId);
    console.log("b2c instance: " + instance);
    console.log("b2c authorities.signIn: " + b2cPolicies.authorities.signIn);
    console.log("b2c authorities.forgotPassword: " + b2cPolicies.authorities.forgotPassword);
    console.log("b2c authorities.name.signIn: " + b2cPolicies.name.signIn);
    console.log("b2c authorities.name.forgotPassword: " + b2cPolicies.name.forgotPassword);
    console.log("b2c redirectUri: " + redirectUri);
    console.log("b2c scopes: " + loginRequest.scopes);
    console.log("b2c useLogLevel: " + useLogLevel);
  }

  /**
   * Configuration object to be passed to MSAL instance on creation.
   * For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
   * For more details on using MSAL.js with Azure AD B2C, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
   */

  const msalConfig: Configuration = {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field; everything else is optional.
      authority: b2cPolicies.authorities.signIn, // Choose sign-up/sign-in user-flow as your default.
      knownAuthorities: [instance.substring(8)], // You must identify your tenant's domain as a known authority.
      redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              if (useLogLevel >= LogLevel.Error) {
                console.error(message);
              }
              return;
            case LogLevel.Info:
              if (useLogLevel >= LogLevel.Info) {
                console.info(message);
              }
              return;
            case LogLevel.Verbose:
              if (useLogLevel >= LogLevel.Verbose) {
                console.debug(message);
              }
              return;
            case LogLevel.Warning:
              if (useLogLevel >= LogLevel.Warning) {
                console.warn(message);
              }
              return;
            default:
              return;
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  };

  mInstance = new PublicClientApplication(msalConfig);
};

export function initMsalInstance(
  apiEmployeeUrl: string,
  b2cDomain: string,
  b2cClientId: string,
  b2cInstance: string,
  useLogLevel = LogLevel.Info
): PublicClientApplication {
  if (mInstance !== null) {
    console.warn("initMsalInstance exist");
    return mInstance;
  }

  apiUrl.employee = apiEmployeeUrl;
  setConfiguration(b2cDomain, b2cClientId, b2cInstance, `${window.location.origin}/oauth2-redirect`, useLogLevel);

  if (!User.useMockedToken("db3f55a4-277c-49ba-8f7a-70f553d37db4")) {
    mInstance.initialize();
  }

  return mInstance;
}

export async function msalInstance(): Promise<PublicClientApplication> {
  let keepTrying;
  let retryTime = 60;

  do {
    if (mInstance === null) {
      if (retryTime > 600) {
        console.log(
          `Wait for ${retryTime} ms (max 3000 ms), on function 'initMsalInstance(configuration)' to use msalInstance...`
        );
      }
      await timeout(retryTime);
      keepTrying = true;
    } else {
      keepTrying = false;
    }

    retryTime += 60;
    if (retryTime > 3000) {
      throw Error("Exception - Check if initMsalInstance(configuration) is missing!");
    }
  } while (keepTrying);

  return mInstance;
}

/* Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [""],
};

export const apiUrl = {
  employee: "",
};
