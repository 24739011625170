import { LogLevel } from "@azure/msal-browser";
/*
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  name: {
    signIn: "b2c_1_signin",
    forgotPassword: "b2c_1_resetpassword",
  },
  authorities: {
    signIn:
      "https://${process.env.VUE_APP_B2C_INSTANCE as string}/${process.env.VUE_APP_B2C_DOMAIN as string}/b2c_1_signin",
    forgotPassword:
      "https://${process.env.VUE_APP_B2C_INSTANCE as string}/${process.env.VUE_APP_B2C_DOMAIN as string}/b2c_1_resetpassword",
  },
};
